import React from 'react';

import Layout from '../components/Layout';
import placeholder from '../assets/images/bg-white.jpg';

import birthday1 from '../assets/images/birthday-01.jpg';
import birthday2 from '../assets/images/birthday-02.jpg';
import birthday3 from '../assets/images/birthday-03.jpg';
import birthday4 from '../assets/images/birthday-04.jpg';
import birthday5 from '../assets/images/birthday-05.jpg';
import birthday6 from '../assets/images/birthday-06.jpg';
import birthday7 from '../assets/images/birthday-07.jpg';
import birthday8 from '../assets/images/birthday-08.jpg';
import birthday9 from '../assets/images/birthday-09.jpg';
import birthday10 from '../assets/images/birthday-10.jpg';
import birthday11 from '../assets/images/birthday-11.jpg';
import birthday12 from '../assets/images/birthday-12.jpg';
import birthday13 from '../assets/images/birthday-13.jpg';
import birthday14 from '../assets/images/birthday-14.jpg';
import birthday15 from '../assets/images/birthday-15.jpg';
import birthday16 from '../assets/images/birthday-16.jpg';
import birthday17 from '../assets/images/birthday-17.jpg';
import birthday18 from '../assets/images/birthday-18.jpg';
import birthday19 from '../assets/images/birthday-19.jpg';
import birthday20 from '../assets/images/birthday-20.jpg';
import birthday21 from '../assets/images/birthday-21.jpg';
import birthday22 from '../assets/images/birthday-22.jpg';
import birthday23 from '../assets/images/birthday-23.jpg';
import birthday24 from '../assets/images/birthday-24.jpg';
import birthday25 from '../assets/images/birthday-25.jpg';
import birthday26 from '../assets/images/birthday-26.jpg';
import birthday27 from '../assets/images/birthday-27.jpg';
import birthday28 from '../assets/images/birthday-28.jpg';
import birthday29 from '../assets/images/birthday-29.jpg';
import birthday30 from '../assets/images/birthday-30.jpg';
import birthday31 from '../assets/images/birthday-31.jpg';
import birthday32 from '../assets/images/birthday-32.jpg';
import birthday33 from '../assets/images/birthday-33.jpg';
import birthday34 from '../assets/images/birthday-34.jpg';
import birthday35 from '../assets/images/birthday-35.jpg';
import birthday36 from '../assets/images/birthday-36.jpg';
import birthday37 from '../assets/images/birthday-37.jpg';
import birthday38 from '../assets/images/birthday-38.jpg';

const IndexPage = () => (
  <Layout activeLink="Birthday">
    
    <section>
      <div className="container">
        
        <div className="row">
          
          {/* Birthday 1 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday1}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Birthday Set</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Adult Cake</h6>
              <p class="card-text">A perfect set for your birthday.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
          {/* Birthday 2 */}
          <div className="col-sm card">
            <div>
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday2}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Daddy</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Adult Cake</h6>
              <p class="card-text">A perfect gift for dad.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
           {/* Birthday 3 */}
           <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday3}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Baby Shark</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Kids Cake</h6>
              <p class="card-text">Perfect for young children.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

        </div>
      </div>
    </section> 

    <section>
      <div className="container">
        
        <div className="row">
          
          {/* Birthday 4 */}
          <div className="col-sm card">
            <div class="wrapper">
              <div>
                <img
                  className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                  src={birthday4}
                  alt=""
                />
              </div>
            </div>
            <div class="card-body">
              <h5 class="card-title">Tenor Saxophone</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Adult Cake</h6>
              <p class="card-text">A perfect cake for the musician.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
          {/* Birthday 5 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday5}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Rainbow</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Kids Cake</h6>
              <p class="card-text">A perfect cake for infants.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
           {/* Birthday 6 */}
           <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday6}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Barbie</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Kids Cake</h6>
              <p class="card-text">Perfect for the Barbie fan.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

        </div>
      </div>
    </section> 

    <section>
      <div className="container">
        
        <div className="row">
          
          {/* Birthday 7 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday7}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Tall Baby Shark</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Kids Cake</h6>
              <p class="card-text">Perfect for young children.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
          {/* Birthday 8 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday8}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Harry Potter</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Kids Cake</h6>
              <p class="card-text">Perfect for the Harry Potter fan.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
           {/* Birthday 9 */}
           <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday9}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Plant Pot and Veggies</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Adult</h6>
              <p class="card-text">A perfect cake for gardeners.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

        </div>
      </div>
    </section> 

    <section>
      <div className="container">
        
        <div className="row">
          
          {/* Birthday 10 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday10}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">White Chocolate Sail & Drip</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Adult Cake</h6>
              <p class="card-text">Flowers and cake all in one.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
          {/* Birthday 11 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday11}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Roblox</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Kids Cake</h6>
              <p class="card-text">Perfect for gamers.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
           {/* Birthday 12 */}
           <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday12}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Fairy Toadstool</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Kids Cake</h6>
              <p class="card-text">Perfect for the young birthday girl.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

        </div>
      </div>
    </section> 

    <section>
      <div className="container">
        
        <div className="row">
          
          {/* Birthday 13 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday13}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">White Chocolate Drip</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Adult Cake</h6>
              <p class="card-text">A colourful extravagant cake.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
          {/* Birthday 14 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday14}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Princess Castle</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Kids Cake</h6>
              <p class="card-text">A perfect castle for a perfect princess.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
           {/* Birthday 15 */}
           <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday15}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Thomas the Tank Engine</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Kids Cake</h6>
              <p class="card-text">A perfect cake for your young boy.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

        </div>
      </div>
    </section> 

    <section>
      <div className="container">
        
        <div className="row">
          
          {/* Birthday 16 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday16}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Puppy in a Box</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Kids Cake</h6>
              <p class="card-text">A cake for the young animal lover.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
          {/* Birthday 17 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday17}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Dump Truck</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Adult Cake</h6>
              <p class="card-text">A perfect cake for young children.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
           {/* Birthday 18 */}
           <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday18}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Bluey</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Adult Cake</h6>
              <p class="card-text">Wine, Cheese & Cake, perfect!</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

        </div>
      </div>
    </section> 

    <section>
      <div className="container">
        
        <div className="row">
          
          {/* Birthday 19 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday19}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Star Wars</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Kids Cake</h6>
              <p class="card-text">Cake enjoy you will.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
          {/* Birthday 20 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday20}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Lightning Mcqueen</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Kids Cake</h6>
              <p class="card-text">Perfect for any Cars fan.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
           {/* Birthday 21 */}
           <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday21}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Under the Sea</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Adult Cake</h6>
              <p class="card-text">Perfect for the bike lover.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

        </div>
      </div>
    </section> 

    <section>
      <div className="container">
        
        <div className="row">
          
          {/* Birthday 22 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday22}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Superhero</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Kids Cake</h6>
              <p class="card-text">Perfect for the young hero.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
          {/* Birthday 23 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday23}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Father & Son</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Adult</h6>
              <p class="card-text">A perfect cake for father & son.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
           {/* Birthday 24 */}
           <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday24}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Camera</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Adult</h6>
              <p class="card-text">A picture perfect cake.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

        </div>
      </div>
    </section> 

    <section>
      <div className="container">
        
        <div className="row">
          
          {/* Birthday 25 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday25}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Bee Hive</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Kids Cake</h6>
              <p class="card-text">Perfect for the young hero.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
          {/* Birthday 26 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday26}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Fresh Flowers with Golden Drip</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Adult</h6>
              <p class="card-text">A perfect cake for father & son.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
           {/* Birthday 27 */}
           <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday27}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Chocolate Drip</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Adult</h6>
              <p class="card-text">A picture perfect cake.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

        </div>
      </div>
    </section> 

    <section>
      <div className="container">
        
        <div className="row">
          
          {/* Birthday 28 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday28}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Buttercream Garden Flowers #1</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Kids Cake</h6>
              <p class="card-text">Perfect for the young hero.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
          {/* Birthday 29 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday29}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Buttercream Garden Flowers #2</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Adult</h6>
              <p class="card-text">A perfect cake for father & son.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
           {/* Birthday 30 */}
           <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday30}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Buttercream Garden Flowers #3</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Adult</h6>
              <p class="card-text">A picture perfect cake.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

        </div>
      </div>
    </section> 

    <section>
      <div className="container">
        
        <div className="row">
          
          {/* Birthday 31 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday31}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Buttercream Flower Fault Line</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Kids Cake</h6>
              <p class="card-text">Perfect for the young hero.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
          {/* Birthday 32 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday32}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Flower in a Cone Drip</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Adult</h6>
              <p class="card-text">A perfect cake for father & son.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
           {/* Birthday 33 */}
           <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img custom-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday33}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Flower Dress</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Adult</h6>
              <p class="card-text">A picture perfect cake.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

        </div>
      </div>
    </section> 

    <section>
      <div className="container">
        
        <div className="row">
          
          {/* Birthday 34 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday34}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Pink Ombre</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Kids Cake</h6>
              <p class="card-text">Perfect for the young hero.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
          {/* Birthday 35 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday35}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Mint Chocolate Drip</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Adult</h6>
              <p class="card-text">A perfect cake for father & son.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
           {/* Birthday 36 */}
           <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img custom-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday36}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Loveheart Buttercream Flower</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Adult</h6>
              <p class="card-text">A picture perfect cake.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

        </div>
      </div>
    </section> 

    <section>
      <div className="container">
        
        <div className="row">
          
          {/* Birthday 37 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday37}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Unicorn Flowers</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Kids Cake</h6>
              <p class="card-text">Perfect for the young hero.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
          {/* Birthday 38 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={birthday38}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Rainbow Unicorn</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Adult</h6>
              <p class="card-text">A perfect cake for father & son.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
           {/* Birthday 36 */}
           <div className="col-sm card">
            <img
              className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
              src={placeholder}
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title"> </h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Adult</h6>
              <p class="card-text">A picture perfect cake.</p> */}
              {/* <a href="/contact/" className="card-link">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

        </div>
      </div>
    </section> 

    <section className="page-section cta">
      <div className="container">
        <div className="row">
          <div className="col-xl-9 mx-auto">
            <div className="text-center rounded">
              <section>
                <header>
                  {/* <h3>Table</h3> */}
                </header>
                <div className="table-wrapper">
                  <table className="default">
                    <thead>
                      <tr>
                        <th><h1><a href="https://www.facebook.com/Designer-sweet-treats-144017559724157/?tn-str=k*F" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook-square social"></i></a></h1></th>
                        <th><h1><a href="https://www.instagram.com/designer_sweet_treats/?hl=en" target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram social"></i></a></h1></th>
                        <th><h1><a href="mailto:jeeranan@designersweettreats.net?subject=New%20Enquiry&body=Please%20tell%20us%20the%20following%20information%3A%0D%0A%0D%0AName%3A%20%0D%0AEvent%20Date%3A%20%0D%0AFlavours%3A%0D%0ANumber%20of%20Servings%3A%0D%0ACake%20Design%3A%0D%0AContact%20Number%3A%0D%0A%0D%0AIf%20possible%20please%20also%20attach%20a%20sketch%20or%20photo%20of%20the%20cake%20you%20would%20like." target="_top"><i class="far fa-envelope social"></i></a></h1></th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </section>

            </div>
          </div>
        </div>
      </div>
    </section>

  </Layout>
);

export default IndexPage;
